import React from "react"
import BeeboRecruitButton from "./beeboRecruitButton"

const BeeboTalentRecruit = () => {
  return (
    <div className="beebo-talent-recruit">
      <div className="content-wrapper">
        <div className="talento">Basta Beebo Rider, hard worker! </div>
        <div className="description-container">
          <div>
            Mag-deliver kahit ilang ulit, dahil sa Servbees, sky's the limit!
            Kumita sa sarili mong oras, at isabay ang gig sa iba pang hilig!
          </div>
        </div>
        <div>
          <BeeboRecruitButton
            text={"Become a Beebo Rider"}
            width={208}
            marginTop={25}
            height={56}
          />
        </div>
      </div>
    </div>
  )
}

export default BeeboTalentRecruit
