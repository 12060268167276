import React from "react"
import BeeboRider from "../../assets/images/beebo-rider/beebo-rider.png"
import RequirementsImage from "../../assets/images/beebo-rider/requirements-image.png"
import User from "../../assets/images/beebo-rider/User.svg"
import VerifiedDocument from "../../assets/images/beebo-rider/Verified Document.svg"
import Summary from "../../assets/images/beebo-rider/Summary.svg"
import BeeboRecruitButton from "./beeboRecruitButton"
import { Link } from "gatsby"

const BeeboHowTo = () => {
  return (
    <div className="beebo-how-to">
      <div className="how-to-content-wrapper">
        <div className="how-to-steps-container">
          <div className="how-to-steps">
            <div className="how-to-header">Paano ba maging Beebo Rider?</div>
            <div className="instructions-container">
              <div className="step">
                <div className="step-number">Step 1</div>
                <div className="step-instruction">
                  <div className="instruction-text">
                    Sagutan ang form dito sa{" "}
                    <Link className="link" to="/beebo-rider/apply">
                      link
                    </Link>
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="step-number">Step 2</div>
                <div className="step-instruction">
                  <div className="instruction-text">
                    Kunan ng litrato ang iyong mga dokumento.
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="step-number">Step 3</div>
                <div className="step-instruction">
                  <div className="instruction-text">
                    Ilakip ang mga litrato sa form at ipasa.
                  </div>
                </div>
              </div>
            </div>
            <div className="once-approved">
              Kapag approved na, pwede na agad umarangkada at kumita!
            </div>
          </div>
          <div className="how-to-image">
            <img src={BeeboRider} />
          </div>
        </div>
        <div className="requirements-container">
          <div className="requirements-image">
            <img src={RequirementsImage} width={"679.38px"} />
          </div>
          <div className="requirements-text-container">
            <div className="requirements-text-header">
              Ano-anong kailangan ko para maging Beebo Rider?
            </div>
            <div className="requirements-description">
              Basta may motorsiklo, pwede rito! Mag-apply na gamit ang mga
              sumusunod na dokumento:
            </div>
            <div className="requirements-detail-section">
              <div className="vertical-bar"></div>
              <div className="requirements-detail-container">
                <div className="detail-item">
                  <div className="logo-container">
                    <img src={User} />
                  </div>
                  <div className="detail-text">
                    Driver's license (malinaw at valid dapat ang lisensya; pwede
                    ang non-professional)
                  </div>
                </div>
                <div className="detail-item">
                  <div className="logo-container">
                    <img src={VerifiedDocument} />
                  </div>
                  <div className="detail-text">
                    OR/CR ng motorsiklo (malinaw at valid dapat ang rehistro)
                  </div>
                </div>
                <div className="detail-item">
                  <div className="logo-container">
                    <img src={Summary} />
                  </div>
                  <div className="detail-text">Pinakabagong bio-data</div>
                </div>
              </div>
            </div>
            <div className="requirements-description">
              Ganun lang kadali, kaya 'wag nang magpahuli! Apply na!
            </div>
            <div className="btn-container">
              <BeeboRecruitButton
                text={"Be a Beebo Rider!"}
                height={57}
                width={214}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeeboHowTo
