import React from "react"
import BeeboRecruitButton from "./beeboRecruitButton"
import banner from "../../assets/images/beebo-rider/Beebo Hero.png"

const BeeboHero = () => {
  return (
    <div className="beebo-hero">
      <div className="beebo-content-wrapper">
        <div className="beebo-content-holder">
          <div className="apply-section-container">
            <div className="apply-header">
              <div>Ang beeda ng kalsada,</div>
              <div>unli ang kita!</div>
            </div>
            <div className="apply-description">
              Be a Beebo Rider and earn as much as P20,000 on the side! Deliver
              where you want, when you want, and decide your hustle's growth!
            </div>
            <div className="apply-button">
              <BeeboRecruitButton
                text={"Paano maging Beebo Rider?"}
                width={"250px"}
                height={"57px"}
              />
            </div>
          </div>
          <div className="hero-image-container">
            <img src={banner} className="hero-image" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeeboHero
