import React from "react"

// components
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BeeboHero from "../../components/beebo-rider/beeboHero"
import BeeboRecruit from "../../components/beebo-rider/beeboRecruit"
import BeeboHowTo from "../../components/beebo-rider/beeboHowTo"
import BeeboTalentRecruit from "../../components/beebo-rider/beeboTalentRecruit"
import BeeboGuideFooter from "../../components/beebo-rider/beeboGuideFooter"

const BeeboRider = () => {

  return (
    <Layout className="beebo-rider bg-design vector-dash blue-footer" style={{paddingTop: 0, backgroundColor: "#F9F9F9"}}>
      <SEO
        pageTitle="Become a Beebo Rider!"
        title="Beebo Rider | Servbees"
        description="How to become a Beebo Rider"
      />
      <BeeboHero />
      <BeeboRecruit />
      <BeeboHowTo />
      <BeeboTalentRecruit />
      <BeeboGuideFooter />
    </Layout>
  )
}

export default BeeboRider
