import React from "react"
import BeeboRecruitButton from "./beeboRecruitButton"
import neighborhood from "../../assets/images/beebo-rider/neighborhood frame.png"

const BeeboRecruit = () => {
  return (
    <div className="beebo-neighborhood">
      <div className="content-wrapper">
        <div className="content-holder">
          <div className="recruit-text-container">
            <div className="recruit-text-header">
              <div>Deliver goods in the UP neighborhood!</div>
            </div>
            <div className="recruit-text-description">
              More and more Beebo Riders are joining the exclusive Servbees hub
              in UP Diliman!
            </div>
            <div className="recruit-text-description">
              Lumalawak na ang Servbees sa Metro Manila, kaya maghanda nang
              umarangkada!
            </div>
            <div className="apply-btn">
              <BeeboRecruitButton
                text={"I want to apply!"}
                width={147}
                height={57}
              />
            </div>
          </div>
          <div className="neighborhood-image">
            <img src={neighborhood} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeeboRecruit
