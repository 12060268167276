import React from "react"

const BeeboGuideFooter = () => {
  return (
    <div className="beebo-guide-footer">
      <div className="content-wrapper">
        <div className="need-help">
          May tanong tungkol sa pagiging Beebo Rider?
        </div>
        <div>
          <button className="btn btn-guide">Basahin ang guide</button>
        </div>
      </div>
    </div>
  )
}

export default BeeboGuideFooter
