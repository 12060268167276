import { Link } from "gatsby"
import React from "react"

const BeeboRecruitButton = ({ text, width, marginTop, height }) => {
  return (
    <Link to="/beebo-rider/apply">
      <button
        className="btn btn-recruit"
        style={{
          width: width,
          marginTop: marginTop,
          height: height,
          zIndex: 10,
        }}
      >
        {text}
      </button>
    </Link>
  )
}

export default BeeboRecruitButton
